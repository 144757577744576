
body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: white;
    line-height: 30px;
    text-align: center;
    background-color: #eeeeee
}

strong { font-weight: 500; }

a, a:hover, a:focus {
    color: #e86121;
    text-decoration: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

h1, h2 {
    margin-top: 10px;
    font-size: 38px;
    font-weight: 100;
    color: #FFF;
    line-height: 50px;
}

h3 {
    font-size: 22px;
    font-weight: 300;
    color: white;
    line-height: 30px;
}

#header {
    color:#424242;
    background-color: #1F2124;
    padding: 10px;
    width: 100%;
    max-width: 1024px;
    margin :auto;

}

#footer {
    color:#424242;
    padding: 10px;
    width: 100%;
    max-width: 1024px;
    margin :auto;
    opacity: 0.5;
}


.footer-line {
    color:#969696;
    border: 1px solid #DBDBDB;

    width: 70%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin :auto;

}

img { max-width: 100%; }

::-moz-selection { background: #e86121; color: #fff; text-shadow: none; }
::selection { background: #e86121; color: #fff; text-shadow: none; }


.btn-link-1 {
    display: inline-block;
    height: 50px;
    margin: 5px;
    padding: 16px 20px 0 20px;
    background: #e86121;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
}
.btn-link-1:hover, .btn-link-1:focus, .btn-link-1:active { outline: 0; opacity: 0.6; color: #fff; }

.btn-link-1.btn-link-1-facebook { background: #4862a3; }
.btn-link-1.btn-link-1-twitter { background: #55acee; }
.btn-link-1.btn-link-1-google-plus { background: #dd4b39; }

.btn-link-1 i {
    padding-right: 5px;
    vertical-align: middle;
    font-size: 20px;
    line-height: 20px;
}

.btn-link-2 {
    display: inline-block;
    height: 50px;
    margin: 5px;
    padding: 15px 20px 0 20px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
}
.btn-link-2:hover, .btn-link-2:focus,
.btn-link-2:active, .btn-link-2:active:focus { outline: 0; opacity: 0.6; background: rgba(0, 0, 0, 0.3); color: #fff; }


/***** Top content *****/

.inner-bg {
    padding: 100px 0 170px 0;
}

.top-content .text {
    color: #fff;
}

.top-content .text h1 { color: #fff; }

.top-content .description {
    margin: 20px 0 10px 0;
}

.top-content .description p { opacity: 0.8; }

.top-content .description a {
    color: #fff;
}
.top-content .description a:hover,
.top-content .description a:focus { border-bottom: 1px dotted #fff; }

.form-box {
    margin-top: 35px;
}

.form-top {
    overflow: hidden;
    padding: 0 25px 15px 25px;
    background: #e86121;
    -moz-border-radius: 4px 4px 0 0; -webkit-border-radius: 4px 4px 0 0; border-radius: 4px 4px 0 0;
    text-align: left;
}

.form-top-left {
    float: left;
    width: 75%;
    padding-top: 25px;
}

.form-top-left h3 { margin-top: 0; }

.form-top-right {
    float: left;
    width: 25%;
    padding-top: 5px;
    font-size: 66px;
    color: #e86121;
    line-height: 100px;
    text-align: right;
}

.form-bottom {
    padding: 25px 25px 30px 25px;
    background: #FFF;
    -moz-border-radius: 0 0 4px 4px; -webkit-border-radius: 0 0 4px 4px; border-radius: 0 0 4px 4px;
    text-align: left;
}

.form-bottom form textarea {
    height: 100px;
}

.form-bottom form button.btn {
    width: 100%;
}

.form-bottom form .input-error {
    border-color: #e86121;
}

.social-login {
    margin-top: 35px;
}

.social-login h3 {
    color: #fff;
}

.social-login-buttons {
    margin-top: 25px;
}


/***** Media queries *****/

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 767px) {

    .inner-bg { padding: 60px 0 110px 0; }

}

@media (max-width: 415px) {

    h1, h2 { font-size: 32px; }

}

input[type="text"],
input[type="password"],
textarea,
textarea.form-control {
    height: 50px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    background: #f8f8f8;
    border: 3px solid #ddd;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: #888;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

textarea,
textarea.form-control {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 30px;
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus,
textarea.form-control:focus {
    outline: 0;
    background: #fff;
    border: 3px solid #ccc;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
}

input[type="text"]:-moz-placeholder, input[type="password"]:-moz-placeholder,
textarea:-moz-placeholder, textarea.form-control:-moz-placeholder { color: #888; }

input[type="text"]:-ms-input-placeholder, input[type="password"]:-ms-input-placeholder,
textarea:-ms-input-placeholder, textarea.form-control:-ms-input-placeholder { color: #888; }

input[type="text"]::-webkit-input-placeholder, input[type="password"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder, textarea.form-control::-webkit-input-placeholder { color: #888; }



button.btn {
    height: 50px;
    margin: 0;
    padding: 0 20px;
    vertical-align: middle;
    background: #e86121;
    border: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 50px;
    color: #fff;
    -moz-border-radius: 4px; -webkit-border-radius: 4px; border-radius: 4px;
    text-shadow: none;
    -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none;
    -o-transition: all .3s; -moz-transition: all .3s; -webkit-transition: all .3s; -ms-transition: all .3s; transition: all .3s;
}

button.btn:hover { opacity: 0.6; color: #fff; }

button.btn:active { outline: 0; opacity: 0.6; color: #fff; -moz-box-shadow: none; -webkit-box-shadow: none; box-shadow: none; }

button.btn:focus { outline: 0; opacity: 0.6; background: #e86121; color: #fff; }

button.btn:active:focus, button.btn.active:focus { outline: 0; opacity: 0.6; background: #e86121; color: #fff; }
/*    --------------------------------------------------
Alerts
-------------------------------------------------- */

.error-notice {
  margin: 5px 5px; /* Making sure to keep some distance from all side */
}
.oaerror {
  width: 90%; /* Configure it fit in your design  */
  margin: 0 auto; /* Centering Stuff */
  background-color: #FFFFFF; /* Default background */
  padding: 20px;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
  margin: 0 auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}
.oaerror li{
  list-style-type: none;
}
.danger {
  border-left-color: #d9534f; /* Left side border color */
  background-color: rgba(217, 83, 79, 0.1); /* Same color as the left border with reduced alpha to 0.1 */
}
.danger strong {
  color:  #d9534f;
}
.warning {
  border-left-color: #f0ad4e;
  background-color: rgba(240, 173, 78, 0.1);
}
.warning strong {
  color: #f0ad4e;
}
.info {
  border-left-color: #5bc0de;
  background-color: rgba(91, 192, 222, 0.1);
}
.info strong {
  color: #5bc0de;
}
.success {
  border-left-color: #00A003;
  background-color: rgba(43, 84, 44, 0.1);
}
.success strong {
  color: #086A0A;
}


html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
}
.footer p {
    color:black;
}